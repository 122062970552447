'use client'

import { RelatedStories } from '@news12/storybook-news'

import React, { useMemo } from 'react'

import formatTime from '../../utilities/format-time'

import { Element, Text } from 'html-dom-parser'

export type StoryListProps = {
  domNode: Element
}

const StoryList = ({ domNode }: StoryListProps) => {
  const storyList = useMemo(() => {
    const recentStories = (domNode.children
      ?.filter((child: Element) => child?.children && child?.name === 'div')
      ?.slice(0, 3) ?? []) as Element[]

    return recentStories.map(story => ({
      heading: story.attribs['data-title'],
      href: story.attribs['data-slug'],
      publishedAt: formatTime(story.attribs['data-published'])
    }))
  }, [domNode])
  const title = useMemo(() => {
    const paragraphEl = domNode.children?.find((child: Element) => child?.name === 'p') as Element
    const paragraphElText = paragraphEl.children[0] as Text

    return paragraphElText?.data ?? ''
  }, [domNode])

  return <RelatedStories title={title} stories={storyList} />
}

export default StoryList
